
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BModal,
  BvTableFieldArray,
  VBTooltip,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { AvSkeletonTable } from "@/components/av-skeleton";
import { AvButton } from "@/components";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ValidateBrandFilters from "./ValidateBrandFilters.vue";
import Ripple from "vue-ripple-directive";
import { Timestamp } from "firebase/firestore";
import useTable from "@core/utils/useTable";

// Services
import { useArchive, useBrandValidation } from "@/services";

// Interfaces
import { IBrandValidation } from "@/store/firebase/firebaseState";
import { convertDateTimeToString } from "@/services/utils";
import { IResponseGetArchives } from "@core/services/interfaces/geral/archive/IArchiveService";
import { IOption, IUserData } from "@core/services/interfaces/IUtil";

@Component({
  name: "ModelsTab",
  components: {
    AvSkeletonTable,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BModal,
    vSelect,
    BCardText,
    ValidateBrandFilters,
    AvButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
})
export default class ModelsTab extends Vue {
  brandId: string = "";
  modalConfirmShow: boolean = false;
  loadingDelete: boolean = false;
  validationTypeOptions: IOption[] = [];

  tableColumns: BvTableFieldArray = [
    {
      key: "Data",
      label: "Data de Requisição",
      sortable: true,
    },
    { key: "DataInicio", label: "Data de Início", sortable: true },
    { key: "DataTermino", label: "Data Final" },
    { key: "Status", label: "Status", sortable: true },
    { key: "Sucesso", label: "Sucesso", sortable: true },
    { key: "Arquivo", label: "Arquivo" },
    { key: "Tipo", label: "Tipo", sortable: true },
    { key: "actions", label: "Ação", tdClass: "text-center" },
  ];

  useTable = new useTable(this.tableColumns, {
    sortBy: "Data",
    isSortDirDesc: true,
  });

  // Computeds
  get brandValidations(): IBrandValidation[] {
    const billings: IBrandValidation[] =
      this.$store.getters["firebase/brandValidations"];
    const userData: IUserData | null = JSON.parse(
      localStorage.getItem("userData") || ""
    );

    if (userData) {
      return billings.filter((it) => it.GrupoId == userData.groupId);
    } else {
      return [];
    }
  }

  // LifeCycle
  created() {
    this.$store
      .dispatch("firebase/startFirestoreRealTimeTrackingValidateBrand")
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar lista de validações da marca!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    this.fetchListValidationTypes();
  }

  destroyed() {
    this.$store.dispatch("firebase/stopFirestoreRealTimeTrackingValidateBrand");
  }

  // Watchs
  @Watch("brandValidations")
  fetchBrandValidations() {
    this.useTable.listTable.value = this.brandValidations;
    this.useTable.filterItems();
    this.useTable.firstRequest.value = false;
  }

  // Methods
  fetchListValidationTypes() {
    this.validationTypeOptions =
      useBrandValidation.requestGetBrandValidationType();
  }

  getDate(date: Timestamp): string {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    return convertDateTimeToString(date.toDate(), "pt-BR", options);
  }

  async handleDownload(item: IBrandValidation) {
    item.Downloading = true;
    const path: string | null = await useArchive
      .requestGetArchives({
        MetaDados: "ValidacaoMarca",
      })
      .then((response: IResponseGetArchives): string | null => {
        const archive = response.data.data.find((it) => it.id == item.FileId);

        if (archive) {
          return archive.caminho;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Arquivo não encontrado",
              text: "O arquivo desejado não foi localizado, tente gerar um novo arquivo",
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });

          return null;
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao consultar arquivo",
            text: "Não foi possível consultar o arquivo solicitado",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return null;
      });

    if (path) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = path;

      document.body.appendChild(a);
      a.click();
    }

    item.Downloading = false;
  }

  async confirmDelete() {
    let isDeleted = true;
    const brandValidation = this.brandValidations.find(
      (it) => it.Id == this.brandId
    );

    if (brandValidation) {
      this.loadingDelete = true;

      isDeleted = await useArchive
        .requestDeleteArchive(this.brandId)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });

      if (isDeleted) {
        // Apagando documento firebase
        isDeleted = await this.$store
          .dispatch("firebase/deleteDoc", {
            path: "log_validacao_marca",
            pathSegments: [this.brandId],
          })
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }

      this.loadingDelete = false;
    }

    if (isDeleted) {
      this.modalConfirmShow = false;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Validação excluída com sucesso!",
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
    } else {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Um erro ocorreu ao excluir essa Validação!",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  }

  handleDelete(brandId: string) {
    this.brandId = brandId;
    this.modalConfirmShow = true;
  }

  customSortCompare(a: IBrandValidation, b: IBrandValidation, field: string) {
    if (field === "Data" || field === "DataInicio" || field === "DataTermino") {
      const timestampA = Number(a[field]) || 0;
      const timestampB = Number(b[field]) || 0;

      return timestampA - timestampB;
    }

    // Retorne 0 para manter a ordenação padrão para outras colunas
    return 0;
  }

  resolveValidationType(type: number): string {
    return (
      this.validationTypeOptions.find((it) => it.value == type)?.label ||
      "" + type
    );
  }
}
